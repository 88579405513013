import React, { useState } from 'react';
import { motion } from 'framer-motion';
import WorksGrid from '../components/WorksGrid';

const categories = ['All', 'Characters', 'Products', 'Animations'];

const Works = () => {
  const [activeCategory, setActiveCategory] = useState('all');

  return (
    <div className="min-h-screen bg-black text-white pt-20">
      <div className="dotted-pattern" />
      
      <div className="content-wrapper">
        <div className="container mx-auto px-4 py-8">
          {/* Header */}
          <motion.h1 
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-5xl font-bold text-white mb-8"
          >
            Our Works
          </motion.h1>

          {/* Category Filter */}
          <div className="flex justify-center mb-12">
            <div className="bg-[#1a1a1a]/80 backdrop-blur-md rounded-full p-2 flex gap-2 justify-center items-center">
              {categories.map((category, index) => (
                <motion.button
                  key={category}
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                  onClick={() => setActiveCategory(category.toLowerCase())}
                  className={`works-category-button px-8 py-3 rounded-full text-base font-medium transition-all ${
                    activeCategory === category.toLowerCase()
                      ? 'bg-[#6366f1] text-white'
                      : 'text-gray-300 hover:text-white'
                  }`}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  {category}
                </motion.button>
              ))}
            </div>
          </div>

          {/* Stats Section */}
          <div className="grid grid-cols-2 md:grid-cols-4 gap-6 mb-12">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="stat-card"
            >
              <h3 className="text-4xl font-bold text-white mb-2">150+</h3>
              <p className="text-gray-400">Projects</p>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
              className="stat-card"
            >
              <h3 className="text-4xl font-bold text-white mb-2">50+</h3>
              <p className="text-gray-400">Clients</p>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              className="stat-card"
            >
              <h3 className="text-4xl font-bold text-white mb-2">3+</h3>
              <p className="text-gray-400">Years</p>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5 }}
              className="stat-card"
            >
              <h3 className="text-4xl font-bold text-white mb-2">24/7</h3>
              <p className="text-gray-400">Support</p>
            </motion.div>
          </div>

          {/* Works Grid */}
          <WorksGrid category={activeCategory} />
        </div>
      </div>
    </div>
  );
};

export default Works;
