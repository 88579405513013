const EXCHANGERATE_API_KEY = import.meta.env.VITE_EXCHANGERATE_API_KEY;
const EXCHANGERATE_API = 'https://v6.exchangerate-api.com/v6';
const BACKUP_API_KEY = import.meta.env.VITE_BACKUP_EXCHANGE_API_KEY;

// Cache the rate for 30 minutes
let cachedRate = null;
let cacheTimestamp = null;
const CACHE_DURATION = 30 * 60 * 1000; // 30 minutes in milliseconds

async function fetchFromPrimaryAPI(amount) {
    const response = await fetch(
        `${EXCHANGERATE_API}/${EXCHANGERATE_API_KEY}/pair/USD/NGN/${amount}`
    );
    const data = await response.json();
    if (data.result === 'success') {
        return data.conversion_result;
    }
    throw new Error('Primary API conversion failed');
}

async function fetchFromBackupAPI(amount) {
    const response = await fetch(
        `https://api.exchangerate.host/convert?from=USD&to=NGN&amount=${amount}`
    );
    const data = await response.json();
    if (data.success) {
        return data.result;
    }
    throw new Error('Backup API conversion failed');
}

async function getCurrentRate() {
    try {
        const testAmount = 1;
        const rate = await fetchFromPrimaryAPI(testAmount);
        return rate;
    } catch (error) {
        console.warn('Primary API failed, trying backup:', error);
        try {
            const testAmount = 1;
            const rate = await fetchFromBackupAPI(testAmount);
            return rate;
        } catch (backupError) {
            console.error('All APIs failed:', backupError);
            // Use a fallback rate if all APIs fail
            return 1300; // Updated fallback rate
        }
    }
}

export async function convertUSDToNGN(usdAmount) {
    try {
        const now = Date.now();
        if (cachedRate && cacheTimestamp && (now - cacheTimestamp < CACHE_DURATION)) {
            console.log('Using cached exchange rate');
            return Math.round(usdAmount * cachedRate);
        }

        const rate = await getCurrentRate();
        cachedRate = rate;
        cacheTimestamp = now;
        
        return Math.round(usdAmount * rate);
    } catch (error) {
        console.error('Error converting currency:', error);
        if (cachedRate) {
            console.warn('Using expired cached rate as fallback');
            return Math.round(usdAmount * cachedRate);
        }
        // Use fallback rate as last resort
        return Math.round(usdAmount * 1300);
    }
}
