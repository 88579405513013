import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

// Define all works from each directory
const works = {
  characters: [
    '1.jpg', '11.png', '111.png', '1111.jpg', 'bear.png'
  ].map((file, i) => ({
    id: `char${i}`,
    image: `/works/character development/${file}`,
    category: 'Character Design',
    type: 'image',
    size: i === 0 ? 'large' : 'small'
  })),

  products: [
    'BLUE-1.png', 'BLUE.png', 'BLUE2.png', 'BROWN.png', 'BROWN1.png',
    'IMG_20240109_143706_219.jpg', 'ORANGE.png', 'RED.png', 'Th.png',
    'Ujq.png', 'Untitled design.jpg', 'elegance perf2.png', 'serum.png',
    'untitled3.png', 'untitled5.png', 'untitled6.png', 'untitled7.png',
    'vfx.png'
  ].map((file, i) => ({
    id: `prod${i}`,
    image: `/works/Product Visualization/${file}`,
    category: 'Product Design',
    type: 'image',
    size: i % 5 === 0 ? 'large' : 'small'
  })),

  animations: [
    { file: '3.jpg', type: 'image' },
    { file: '202401190817 (1) (1).mp4', type: 'video' },
    { file: '848643_final.mp4', type: 'video' },
    { file: '877770_final.mp4', type: 'video' },
    { file: 'InShot_20240130_155815144.mp4', type: 'video' },
    { file: 'InShot_20240525_024256468.mp4', type: 'video' },
    { file: 'VID-20240811-WA0042.mp4', type: 'video' },
    { file: 'VID-20240828-WA0027.mp4', type: 'video' },
    { file: 'VID-20240909-WA0058.mp4', type: 'video' },
    { file: 'VID-20241011-WA0047.mp4', type: 'video' },
    { file: 'VID-20241015-WA0006.mp4', type: 'video' },
    { file: 'VID-20241016-WA0003.mp4', type: 'video' },
    { file: 'VID-20241016-WA0004.mp4', type: 'video' },
    { file: 'VID-20241016-WA0005.mp4', type: 'video' },
    { file: 'VID-20241016-WA0006.mp4', type: 'video' },
    { file: 'We.mp4', type: 'video' },
    { file: 'muna.mp4', type: 'video' }
  ].map((item, i) => ({
    id: `anim${i}`,
    source: `/works/Animation/${item.file}`,
    category: 'Animation',
    type: item.type,
    size: i % 3 === 0 ? 'large' : 'small'
  }))
};

// Add 'all' category by combining all works
works.all = [
  ...works.characters,
  ...works.products,
  ...works.animations
];

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onClick={onClose}
      className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/90"
    >
      <motion.div
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.8, opacity: 0 }}
        onClick={e => e.stopPropagation()}
        className="relative bg-black rounded-3xl overflow-hidden"
        style={{ maxWidth: '90vw', maxHeight: '90vh', width: 'auto', height: 'auto' }}
      >
        {children}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-white bg-black/50 rounded-full p-2 hover:bg-black/70"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </motion.div>
    </motion.div>
  );
};

const WorkItem = ({ work }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (work.type === 'video') {
    return (
      <>
        <motion.div
          className={`bento-item ${work.size}`}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          whileHover={{ scale: 1.02 }}
          onClick={() => setIsModalOpen(true)}
        >
          <div className="bento-card">
            <div className="relative h-full">
              <video
                className="w-full h-full object-cover rounded-3xl"
                muted
                autoPlay
                loop
                playsInline
              >
                <source src={work.source} type="video/mp4" />
              </video>
              <div className="absolute bottom-0 left-0 p-6 w-full">
                <div className="flex items-center text-gray-300 text-sm">
                  <span className="w-1.5 h-1.5 bg-gray-300 rounded-full mr-2" />
                  {work.category}
                </div>
              </div>
              <div className="absolute inset-0 bg-black/20 hover:bg-black/30 transition-colors rounded-3xl">
                <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-white opacity-80" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </motion.div>

        <AnimatePresence>
          {isModalOpen && (
            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
              <video
                className="max-w-full max-h-[90vh] h-auto"
                controls
                autoPlay
                muted
              >
                <source src={work.source} type="video/mp4" />
              </video>
            </Modal>
          )}
        </AnimatePresence>
      </>
    );
  }

  return (
    <>
      <motion.div
        className={`bento-item ${work.size}`}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        whileHover={{ scale: 1.02 }}
        onClick={() => setIsModalOpen(true)}
      >
        <div className="bento-card">
          <div className="relative h-full cursor-pointer">
            <img
              src={work.image || work.source}
              alt={work.category}
              className="w-full h-full object-cover rounded-3xl"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/20 to-transparent rounded-3xl" />
            <div className="absolute bottom-0 left-0 p-6 w-full">
              <div className="flex items-center text-gray-300 text-sm">
                <span className="w-1.5 h-1.5 bg-gray-300 rounded-full mr-2" />
                {work.category}
              </div>
            </div>
            <div className="absolute inset-0 bg-black/20 hover:bg-black/30 transition-colors rounded-3xl">
              <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-white opacity-80" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      <AnimatePresence>
        {isModalOpen && (
          <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
            <img
              src={work.image || work.source}
              alt={work.category}
              style={{
                maxWidth: '90vw',
                maxHeight: '90vh',
                width: 'auto',
                height: 'auto',
                objectFit: 'contain'
              }}
            />
          </Modal>
        )}
      </AnimatePresence>
    </>
  );
};

const WorksGrid = ({ category = 'all' }) => {
  const displayWorks = works[category.toLowerCase()] || works.all;

  return (
    <div className="bento-grid">
      {displayWorks.map((work) => (
        <WorkItem key={work.id} work={work} />
      ))}
    </div>
  );
};

export default WorksGrid;
