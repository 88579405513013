import React from 'react';
import { motion } from 'framer-motion';

const Founder = () => {
  return (
    <section className="relative w-full min-h-[250px] flex items-center justify-center overflow-hidden bg-[#0a0a0a] mt-20">
      <div 
        className="absolute inset-0 z-0"
        style={{
          backgroundImage: 'url(/bg.png)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          opacity: 0.4
        }}
      />
      
      <div className="container mx-auto px-4 py-6 z-10">
        <div className="flex flex-col md:flex-row items-center gap-4 max-w-2xl mx-auto">
          <motion.div 
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
            className="w-full md:w-2/5"
          >
            <div className="relative w-32 h-32 md:w-40 md:h-40 rounded-2xl overflow-hidden mx-auto">
              <img 
                src="/founder.jpg" 
                alt="Ogunsola Paul Omololu" 
                className="w-full h-full object-cover"
              />
            </div>
          </motion.div>
          
          <motion.div 
            initial={{ opacity: 0, x: 20 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="w-full md:w-3/5 text-white text-center md:text-left"
          >
            <h2 className="text-xl font-bold mb-1">Ogunsola Paul Omololu</h2>
            <h3 className="text-base text-gray-300 mb-3">Founder & Creative Director</h3>
            <p className="text-gray-300 leading-relaxed text-sm mb-4">
              At Rendercraft Studio, our vision extends beyond digital artistry. We're building towards becoming a pioneering industrial design company, merging cutting-edge 3D visualization with practical design solutions.
            </p>
            <div className="flex items-center justify-center md:justify-start gap-4">
              <a 
                href="https://www.instagram.com/iamthatlolu?igsh=ejN1MWJpZ3d0a282"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:opacity-80 transition-opacity"
              >
                <img src="/icons/instagram.svg" alt="Instagram" className="w-5 h-5" />
              </a>
              <a 
                href="https://www.linkedin.com/in/ogunsola-lolu-2759ba323?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:opacity-80 transition-opacity"
              >
                <img src="/icons/linkedin.svg" alt="LinkedIn" className="w-5 h-5" />
              </a>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Founder;
