import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import PaystackPayment from '../components/PaystackPayment';
import ImageCarousel from '../components/ImageCarousel';
import { generateUniqueReference } from '../utils/generateReference';

const TextureMapGenerator = () => {
  const navigate = useNavigate();
  const [showPaystack, setShowPaystack] = useState(false);
  const [paymentReference, setPaymentReference] = useState('');
  const [email, setEmail] = useState('');
  const [showContactInfo, setShowContactInfo] = useState(false);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');

  const images = [
    '/TMG/header.png',
    '/TMG/image1.png',
    '/TMG/image2.png',
    '/TMG/image3.png'
  ];

  const handlePayment = (contactEmail) => {
    setEmail(contactEmail);
    // Generate unique reference using timestamp and random number
    const timestamp = new Date().getTime();
    const random = Math.floor(Math.random() * 1000000);
    const reference = `TMG_${timestamp}_${random}`;
    setPaymentReference(reference);
    setShowPaystack(true);
    setShowContactInfo(false);
  };

  const handlePaymentSuccess = (response) => {
    setShowPaystack(false);
    // Navigate to download page with proper state
    navigate('/download', {
      state: {
        token: response.reference,  // Use payment reference as download token
        reference: response.reference
      }
    });
  };

  const handlePaymentClose = () => {
    setShowPaystack(false);
  };

  return (
    <div className="min-h-screen bg-black text-white pt-20">
      <div className="dotted-pattern" />
      
      <div className="content-wrapper">
        <div className="container mx-auto px-4 py-8 max-w-4xl">
          {/* Header */}
          <motion.div 
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-center mb-12"
          >
            <h1 className="text-5xl font-bold mb-6">TextureMap Generator Add-on</h1>
            <div className="flex items-center justify-center gap-4">
              <div className="text-2xl text-gray-400 line-through">$20</div>
              <div className="text-3xl font-bold text-[#6366f1]">$18</div>
            </div>
          </motion.div>

          {/* Product Image */}
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            className="mb-12"
          >
            <ImageCarousel images={images} />
          </motion.div>

          {/* Main Content Grid */}
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 mb-12">
            {/* Left Column - Product Description */}
            <div className="lg:col-span-2">
              <div className="bg-[#1a1a1a]/60 backdrop-blur-lg border border-white/10 rounded-2xl p-8 shadow-2xl">
                <h1 className="text-4xl font-bold mb-6">Texture Map Generator Add-on for Blender</h1>
                
                <p className="text-xl text-gray-300 mb-8">
                  Elevate your texturing game with the <span className="text-[#6366f1]">Texture Map Generator Add-on</span>, 
                  designed for Blender users who want to easily generate high-quality texture maps. Say goodbye to complicated 
                  workflows and hello to simplicity and power at your fingertips.
                </p>

                <h2 className="text-2xl font-bold mb-4">What Makes It Special</h2>
                <p className="text-gray-300 mb-6">
                  Forget hours of manual map creation. This addon generates professional-grade texture maps instantly. 
                  Whether you're a game designer, 3D artist, or architectural visualization pro, we've got you covered.
                </p>

                <h3 className="text-xl font-bold mb-4">Key Features</h3>
                <ul className="list-none space-y-3 mb-12">
                  <li className="flex items-start">
                    <span className="text-[#6366f1] mr-2">•</span>
                    <div>
                      <strong>Multiple Map Generation:</strong>
                      <p className="text-gray-400">Create Displacement, Normal, AO, Roughness, and Metallic maps.</p>
                    </div>
                  </li>
                  <li className="flex items-start">
                    <span className="text-[#6366f1] mr-2">•</span>
                    <div>
                      <strong>User-Friendly Panels:</strong>
                      <p className="text-gray-400">Effortlessly navigate the interface and achieve professional results.</p>
                    </div>
                  </li>
                  <li className="flex items-start">
                    <span className="text-[#6366f1] mr-2">•</span>
                    <div>
                      <strong>Automatic File Organization:</strong>
                      <p className="text-gray-400">Keep your workflow clutter-free with structured file saving.</p>
                    </div>
                  </li>
                </ul>

                {/* How It Works Section */}
                <div className="space-y-8 mb-12">
                  <h2 className="text-3xl font-bold mb-8">How It Works</h2>
                  <div className="grid grid-cols-1 gap-12">
                    {/* Step 1 */}
                    <div className="bg-black/20 backdrop-blur-lg border border-white/5 rounded-2xl p-8">
                      <div className="flex flex-col md:flex-row gap-8 items-center">
                        <div className="md:w-1/3">
                          <h3 className="text-2xl font-bold mb-4">1. Import your base texture</h3>
                          <p className="text-gray-400">Import your base texture into Blender</p>
                        </div>
                        <div className="md:w-2/3 rounded-xl overflow-hidden">
                          <img 
                            src="/TMG%20gif/import.gif" 
                            alt="Import texture"
                            className="w-full h-[450px] object-contain"
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>

                    {/* Step 2 */}
                    <div className="bg-black/20 backdrop-blur-lg border border-white/5 rounded-2xl p-8">
                      <div className="flex flex-col md:flex-row gap-8 items-center">
                        <div className="md:w-1/3">
                          <h3 className="text-2xl font-bold mb-4">2. Select and Generate Maps</h3>
                          <p className="text-gray-400">Choose your desired maps and generate them with one click</p>
                        </div>
                        <div className="md:w-2/3 rounded-xl overflow-hidden">
                          <img 
                            src="/TMG%20gif/generate.gif" 
                            alt="Generate maps"
                            className="w-full h-[450px] object-contain"
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>

                    {/* Step 3 */}
                    <div className="bg-black/20 backdrop-blur-lg border border-white/5 rounded-2xl p-8">
                      <div className="flex flex-col md:flex-row gap-8 items-center">
                        <div className="md:w-1/3">
                          <h3 className="text-2xl font-bold mb-4">3. Invert maps if needed</h3>
                          <p className="text-gray-400">Fine-tune your maps with inversion options</p>
                        </div>
                        <div className="md:w-2/3 rounded-xl overflow-hidden">
                          <img 
                            src="/TMG%20gif/invert.gif" 
                            alt="Invert maps"
                            className="w-full h-[450px] object-contain"
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>

                    {/* Step 4 */}
                    <div className="bg-black/20 backdrop-blur-lg border border-white/5 rounded-2xl p-8">
                      <div className="flex flex-col md:flex-row gap-8 items-center">
                        <div className="md:w-1/3">
                          <h3 className="text-2xl font-bold mb-4">4. Create PBR Materials</h3>
                          <p className="text-gray-400">Instantly create PBR materials from your generated maps</p>
                        </div>
                        <div className="md:w-2/3 rounded-xl overflow-hidden">
                          <img 
                            src="/TMG%20gif/material.gif" 
                            alt="Create materials"
                            className="w-full h-[450px] object-contain"
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Organized Outputs */}
                <div className="mb-12">
                  <h2 className="text-2xl font-bold mb-4">Organized Outputs</h2>
                  <p className="text-gray-300 text-lg">
                    Generated maps are saved under <code className="bg-black/30 px-2 py-1 rounded">Documents/TextureMapGen/[Image_Name]</code> for 
                    hassle-free organization.
                  </p>
                </div>

                {/* Product Benefits */}
                <div className="mb-12">
                  <h2 className="text-2xl font-bold mb-6">Product Benefits</h2>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                    <div className="bg-black/20 backdrop-blur-lg border border-white/5 rounded-xl p-6">
                      <h3 className="text-xl font-bold mb-2">Quick Setup</h3>
                      <p className="text-gray-400">Get started in minutes.</p>
                    </div>
                    <div className="bg-black/20 backdrop-blur-lg border border-white/5 rounded-xl p-6">
                      <h3 className="text-xl font-bold mb-2">High Quality</h3>
                      <p className="text-gray-400">Create production-ready texture maps effortlessly.</p>
                    </div>
                    <div className="bg-black/20 backdrop-blur-lg border border-white/5 rounded-xl p-6">
                      <h3 className="text-xl font-bold mb-2">Versatile Use</h3>
                      <p className="text-gray-400">Suitable for games, animations, and visual effects.</p>
                    </div>
                  </div>
                </div>

                {/* Call to Action */}
                <div className="text-center">
                  <h2 className="text-3xl font-bold mb-4">Ready to transform your 3D workflow?</h2>
                  <p className="text-xl text-gray-300">
                    Grab the Texture Map Generator Add-on today and experience professional-level texturing within Blender!
                  </p>
                </div>
              </div>

              {/* More Visuals Section - Detached */}
              <div className="bg-[#1a1a1a]/60 backdrop-blur-lg border border-white/10 rounded-2xl p-8 shadow-2xl mt-8">
                <h2 className="text-3xl font-bold mb-8">More Visuals</h2>
                <div className="grid grid-cols-1 gap-6">
                  <div className="bg-black/20 backdrop-blur-lg border border-white/5 rounded-xl overflow-hidden">
                    <img 
                      src="/TMG gif/Untitled design.gif" 
                      alt="Texture map generation process"
                      className="w-full h-[400px] object-contain"
                      loading="lazy"
                    />
                  </div>
                  <div className="bg-black/20 backdrop-blur-lg border border-white/5 rounded-xl overflow-hidden">
                    <img 
                      src="/TMG gif/Untitled design (1).gif" 
                      alt="Generated maps showcase"
                      className="w-full h-[400px] object-contain"
                      loading="lazy"
                    />
                  </div>
                  <div className="bg-black/20 backdrop-blur-lg border border-white/5 rounded-xl overflow-hidden">
                    <img 
                      src="/TMG gif/Untitled design (2).gif" 
                      alt="Additional texture showcase"
                      className="w-full h-[400px] object-contain"
                      loading="lazy"
                    />
                  </div>
                  <div className="bg-black/20 backdrop-blur-lg border border-white/5 rounded-xl overflow-hidden">
                    <img 
                      src="/TMG gif/TextureMap Gen (8).png" 
                      alt="Texture map example"
                      className="w-full h-[400px] object-contain"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Right Column - Purchase Section */}
            <div className="lg:col-span-1">
              <div className="bg-[#1a1a1a]/60 backdrop-blur-lg border border-white/10 rounded-2xl p-6 shadow-2xl sticky top-24">
                <div className="flex flex-col items-center text-center">
                  <img 
                    src="/TMG/header.png" 
                    alt="TextureMap Generator"
                    className="w-full h-48 object-cover rounded-xl mb-6"
                  />
                  <h1 className="text-2xl font-bold mb-2">TextureMap Generator Addon</h1>
                  <p className="text-gray-400 mb-6">Generate professional texture maps with ease</p>
                  <div className="flex items-center gap-2 mb-4">
                    <span className="text-gray-500 line-through text-xl">$20</span>
                    <span className="text-4xl font-bold text-[#6366f1]">$18</span>
                  </div>
                  <p className="text-sm text-[#10B981] mb-6">10% off - Limited time offer!</p>

                  <button 
                    onClick={() => setShowContactInfo(true)}
                    className="w-full bg-[#6366f1] hover:bg-[#5558e6] text-white font-bold py-3 px-6 rounded-xl transition duration-200 mb-6"
                  >
                    Buy Now
                  </button>
                </div>
              </div>

              {/* Contact Info Modal */}
              {showContactInfo && (
                <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
                  <div className="bg-[#1a1a1a]/95 backdrop-blur-lg border border-white/10 rounded-2xl p-8 shadow-2xl max-w-md w-full mx-4">
                    <h2 className="text-2xl font-bold mb-6">Contact Information</h2>
                    
                    <div className="space-y-4 mb-6">
                      <div>
                        <label className="block text-gray-400 mb-2">Name</label>
                        <input 
                          type="text"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          className="w-full bg-black/20 border border-white/10 rounded-xl px-4 py-2 text-white"
                          placeholder="Your name"
                        />
                      </div>
                      <div>
                        <label className="block text-gray-400 mb-2">Email</label>
                        <input 
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          className="w-full bg-black/20 border border-white/10 rounded-xl px-4 py-2 text-white"
                          placeholder="your@email.com"
                        />
                      </div>
                      <div>
                        <label className="block text-gray-400 mb-2">Phone Number (Optional)</label>
                        <input 
                          type="tel"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          className="w-full bg-black/20 border border-white/10 rounded-xl px-4 py-2 text-white"
                          placeholder="Your phone number"
                        />
                      </div>
                    </div>

                    <div className="flex items-center gap-4 mb-8">
                      <img src="/cards/visa.svg" alt="Visa" className="h-8" />
                      <img src="/cards/verve.svg" alt="Verve" className="h-8" />
                      <img src="/cards/American Express.svg" alt="American Express" className="h-8" />
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="h-8">
                        <path fill="#FF5F00" d="M15.245 17.831h-6.49V6.168h6.49v11.663z"></path>
                        <path fill="#EB001B" d="M9.167 12A7.404 7.404 0 0 1 12 6.169 7.417 7.417 0 0 0 0 12a7.417 7.417 0 0 0 11.999 5.831A7.406 7.406 0 0 1 9.167 12z"></path>
                        <path fill="#F79E1B" d="M24 12a7.417 7.417 0 0 1-12 5.831c1.725-1.358 2.833-3.465 2.833-5.831S13.725 7.527 12 6.169A7.417 7.417 0 0 1 24 12z"></path>
                      </svg>
                    </div>

                    <div className="flex gap-4">
                      <button 
                        onClick={() => setShowContactInfo(false)}
                        className="flex-1 border border-white/10 text-white font-bold py-3 px-6 rounded-xl hover:bg-white/5 transition duration-200"
                      >
                        Cancel
                      </button>
                      <button 
                        onClick={() => {
                          if (!email) {
                            alert('Please enter your email address');
                            return;
                          }
                          handlePayment(email);
                        }}
                        className="flex-1 bg-[#6366f1] hover:bg-[#5558e6] text-white font-bold py-3 px-6 rounded-xl transition duration-200"
                      >
                        Proceed to Pay
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {/* Paystack Payment UI */}
              {showPaystack && (
                <PaystackPayment
                  email={email}
                  amount={18}
                  reference={paymentReference}
                  onSuccess={handlePaymentSuccess}
                  onClose={handlePaymentClose}
                />
              )}
            </div>
          </div>

          {/* Additional Visuals */}
          {/* <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12 max-w-5xl mx-auto">
            <div className="bg-[#1a1a1a]/80 backdrop-blur-md rounded-xl overflow-hidden p-4">
              <img 
                src="/TMG gif/Untitled design.gif" 
                alt="Additional visual 1"
                className="w-full h-[300px] object-contain"
                loading="lazy"
              />
            </div>
            <div className="bg-[#1a1a1a]/80 backdrop-blur-md rounded-xl overflow-hidden p-4">
              <img 
                src="/TMG gif/Untitled design (1).gif" 
                alt="Additional visual 2"
                className="w-full h-[300px] object-contain"
                loading="lazy"
              />
            </div>
            <div className="bg-[#1a1a1a]/80 backdrop-blur-md rounded-xl overflow-hidden p-4">
              <img 
                src="/TMG gif/untitled5.png" 
                alt="Additional visual 3"
                className="w-full h-[300px] object-contain"
                loading="lazy"
              />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default TextureMapGenerator;
