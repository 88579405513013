import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Works from './pages/Works';
import Contact from './pages/Contact';
import Products from './pages/Products';
import TextureMapGenerator from './pages/TextureMapGenerator';
import DownloadPage from './pages/DownloadPage';

function App() {
  return (
    <Router>
      <div className="min-h-screen bg-gradient-to-b from-gray-100 to-gray-900">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/works/:category" element={<Works />} />
          <Route path="/products" element={<Products />} />
          <Route path="/products/texturemap-generator" element={<TextureMapGenerator />} />
          <Route path="/download" element={<DownloadPage />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
