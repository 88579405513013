import React from 'react';
import { motion } from 'framer-motion';

const Contact = () => {
  return (
    <div className="min-h-screen bg-black text-white">
      <div className="dotted-pattern" />
      
      <div className="relative z-10">
        <div className="container mx-auto px-4 py-20">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="max-w-2xl mx-auto"
          >
            <div className="bg-[#1a1a1a]/60 backdrop-blur-lg border border-white/10 rounded-2xl p-8 shadow-2xl">
              <div className="text-center">
                <h1 className="text-4xl font-bold mb-8 bg-gradient-to-r from-[#6366f1] to-[#8b5cf6] bg-clip-text text-transparent">
                  Contact Us
                </h1>

                <div className="space-y-8">
                  <div className="bg-[#1a1a1a]/40 backdrop-blur-sm border border-white/5 rounded-xl p-6">
                    <ul className="space-y-6 text-gray-300">
                      <li className="flex items-center gap-3">
                        <svg className="w-6 h-6 text-[#6366f1]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                        </svg>
                        <span className="text-lg">rendercraftstudio001@gmail.com</span>
                      </li>
                      <li className="flex items-center gap-3">
                        <svg className="w-6 h-6 text-[#6366f1]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                        </svg>
                        <span className="text-lg">+234 703 456 7890</span>
                      </li>
                      <li className="flex items-center gap-3">
                        <svg className="w-6 h-6 text-[#6366f1]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                        </svg>
                        <a 
                          href="https://instagram.com/rendercraft.studio" 
                          target="_blank" 
                          rel="noopener noreferrer"
                          className="text-lg hover:text-[#6366f1] transition-colors"
                        >
                          @rendercraft.studio
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="bg-[#1a1a1a]/40 backdrop-blur-sm border border-white/5 rounded-xl p-6">
                    <p className="text-gray-300">
                      We're here to help! Feel free to reach out through any of the channels above, 
                      and we'll get back to you as soon as possible.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
