import { Link, useLocation } from 'react-router-dom';

const Navbar = () => {
  const location = useLocation();

  const isActive = (path) => {
    if (path === '/') {
      return location.pathname === '/';
    }
    return location.pathname.startsWith(path);
  };

  return (
    <nav className="fixed w-full z-50 top-0 left-0 px-6 py-4">
      <div className="max-w-7xl mx-auto flex items-center justify-center">
        {/* Navigation Group with Logo */}
        <div className="nav-group flex justify-center">
          <Link to="/" className="nav-logo">
            <img 
              src="/logo.svg" 
              alt="Rendercraft Studio" 
              className="h-8 w-8"
            />
          </Link>

          <div className="nav-divider"></div>

          {/* Navigation Links */}
          <div className="flex justify-center w-full">
            <div className="flex justify-center w-full">
              <Link 
                to="/" 
                className={`nav-link ${isActive('/') ? 'active' : ''}`}
              >
                Home
              </Link>
              <Link 
                to="/works/all" 
                className={`nav-link ${isActive('/works') ? 'active' : ''}`}
              >
                Works
              </Link>
              <Link 
                to="/products" 
                className={`nav-link ${isActive('/products') ? 'active' : ''}`}
              >
                Products
              </Link>
              <Link 
                to="/contact" 
                className={`nav-link ${isActive('/contact') ? 'active' : ''}`}
              >
                Contact
              </Link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
