import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import Founder from '../components/Founder';

const Home = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-black text-white">
      <div className="dotted-pattern" />
      
      {/* Video Background */}
      <div className="fixed inset-0 z-0">
        <div className="absolute inset-0 bg-black/50" /> {/* Overlay */}
        <video
          autoPlay
          loop
          muted
          playsInline
          className="w-full h-full object-cover"
        >
          <source src="/showreel.mp4" type="video/mp4" />
        </video>
      </div>

      <div className="content-wrapper">
        <section className="min-h-screen flex items-center justify-center p-4">
          <div className="container">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="text-center max-w-4xl mx-auto"
            >
              <h1 className="text-5xl md:text-7xl font-bold mb-6">
                Rendercraft Studio
              </h1>
              <p className="text-xl md:text-2xl text-gray-400 mb-8">
                Where imagination meets innovation in 3D visualization
              </p>
              {/* Removed the View Works button */}
              <div className="flex justify-center gap-4">
              </div>
            </motion.div>
          </div>
        </section>
        <Founder />
      </div>
    </div>
  );
};

export default Home;
