import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const Products = () => {
  return (
    <div className="min-h-screen bg-black text-white pt-20">
      <div className="dotted-pattern" />
      
      <div className="content-wrapper">
        <div className="container mx-auto px-4 py-8">
          {/* Header */}
          <motion.h1 
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-5xl font-bold text-white mb-12"
          >
            Products
          </motion.h1>

          {/* Products Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {/* TextureMap Generator Card */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="bg-[#1a1a1a]/80 backdrop-blur-md rounded-2xl overflow-hidden"
            >
              <Link to="/products/texturemap-generator" className="block p-6">
                <div className="aspect-square bg-[#2a2a2a] rounded-xl mb-6 flex items-center justify-center overflow-hidden">
                  <img 
                    src="/TMG/header.png" 
                    alt="TextureMap Generator" 
                    className="w-full h-full object-cover"
                  />
                </div>
                <h3 className="text-2xl font-bold mb-2">TextureMap Generator Addon</h3>
                <p className="text-gray-400 mb-4">Generate professional texture maps with ease</p>
                <div className="flex items-center gap-3">
                  <span className="text-lg text-gray-400 line-through">$20</span>
                  <span className="text-2xl font-bold text-[#6366f1]">$18</span>
                </div>
              </Link>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
