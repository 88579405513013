import React, { useEffect, useState } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { motion } from 'framer-motion';

const DownloadPage = () => {
  const location = useLocation();
  const [countdown, setCountdown] = useState(5);
  const [showDownload, setShowDownload] = useState(false);
  const [downloaded, setDownloaded] = useState(false);

  // Check if we have valid state
  if (!location.state?.token || !location.state?.reference) {
    return <Navigate to="/products" replace />;
  }

  useEffect(() => {
    // Check if already downloaded
    const isDownloaded = localStorage.getItem(`download_${location.state.reference}`);
    if (isDownloaded) {
      setDownloaded(true);
    }

    const timer = setInterval(() => {
      setCountdown((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          setShowDownload(true);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [location.state.reference]);

  const handleDownload = async () => {
    if (downloaded) {
      alert('You have already downloaded the file. Please contact support if you need to download again.');
      return;
    }

    try {
      // Use test token for development
      const token = 'test-token-123';
      console.log('Attempting download with token:', token);
      
      const response = await fetch(`http://localhost:3001/api/download/${token}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/zip'
        }
      });

      // Log the response status and headers
      console.log('Response status:', response.status);
      console.log('Response headers:', response.headers);

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Server error response:', errorText);
        throw new Error(`Download failed: ${response.status} ${errorText}`);
      }

      // Get the blob with the full file data
      const blob = await response.blob();
      console.log('Blob size:', blob.size);
      
      // Create download link
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'TextureMapGenerator.zip';
      document.body.appendChild(link);
      link.click();
      
      // Cleanup
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
      
      // Mark as downloaded
      setDownloaded(true);
      localStorage.setItem(`download_${location.state.reference}`, 'true');
    } catch (error) {
      console.error('Download error details:', error);
      alert(`Download failed: ${error.message}. Please check the console for details and contact support if needed.`);
    }
};

  return (
    <div className="min-h-screen bg-black text-white">
      <div className="dotted-pattern" />
      
      <div className="relative z-10">
        <div className="container mx-auto px-4 py-20">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="max-w-2xl mx-auto"
          >
            <div className="bg-[#1a1a1a]/60 backdrop-blur-lg border border-white/10 rounded-2xl p-8 shadow-2xl">
              <div className="text-center">
                <h1 className="text-4xl font-bold mb-8 bg-gradient-to-r from-[#6366f1] to-[#8b5cf6] bg-clip-text text-transparent">
                  Thank You for Your Purchase!
                </h1>

                {!showDownload ? (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className="space-y-6"
                  >
                    <p className="text-xl text-gray-300">Your download will be ready in</p>
                    <div className="relative w-32 h-32 mx-auto">
                      <div className="absolute inset-0 bg-[#6366f1]/20 rounded-full backdrop-blur-sm" />
                      <div className="relative flex items-center justify-center h-full">
                        <span className="text-6xl font-bold text-[#6366f1]">{countdown}</span>
                      </div>
                    </div>
                  </motion.div>
                ) : (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className="space-y-8"
                  >
                    <div className="bg-[#1a1a1a]/40 backdrop-blur-sm border border-white/5 rounded-xl p-6">
                      <p className="text-xl text-gray-300 mb-6">
                        Your TextureMap Generator Add-on is ready for download
                      </p>
                      <button
                        onClick={handleDownload}
                        disabled={downloaded}
                        className={`
                          w-full py-4 px-8 rounded-xl font-bold text-lg transition-all duration-300
                          ${downloaded 
                            ? 'bg-gray-600/50 cursor-not-allowed' 
                            : 'bg-[#6366f1] hover:bg-[#5558e6] hover:shadow-lg hover:shadow-[#6366f1]/20'
                          }
                        `}
                      >
                        {downloaded ? 'Already Downloaded' : 'Download Now'}
                      </button>
                    </div>

                    {downloaded && (
                      <div className="text-center">
                        <p className="text-gray-400">
                          Need to download again? <a href="mailto:rendercraftstudio001@gmail.com" className="text-[#6366f1] hover:text-[#5558e6]">Contact support</a>
                        </p>
                      </div>
                    )}

                    <div className="bg-[#1a1a1a]/40 backdrop-blur-sm border border-white/5 rounded-xl p-6">
                      <h2 className="font-bold text-lg mb-4 text-[#6366f1]">Important Information</h2>
                      <ul className="space-y-3 text-gray-300">
                        <li className="flex items-center gap-2">
                          <svg className="w-5 h-5 text-[#6366f1]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg>
                          Installation guide included in package
                        </li>
                        <li className="flex items-center gap-2">
                          <svg className="w-5 h-5 text-[#6366f1]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg>
                          Download link valid for 24 hours
                        </li>
                        <li className="flex items-center gap-2">
                          <svg className="w-5 h-5 text-[#6366f1]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                          </svg>
                          Support: rendercraftstudio001@gmail.com
                        </li>
                      </ul>
                    </div>
                  </motion.div>
                )}
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default DownloadPage;
