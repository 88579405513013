import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { convertUSDToNGN } from '../utils/currencyConverter';

const PaystackPayment = ({ email, amount, reference, onSuccess, onClose }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [handler, setHandler] = useState(null);

  useEffect(() => {
    let mounted = true;
    
    const initializePayment = async () => {
      try {
        if (!mounted) return;
        setIsLoading(true);
        
        const convertedAmount = await convertUSDToNGN(amount);
        if (!mounted) return;

        // Wait for any existing Paystack iframes to be removed
        const existingIframe = document.querySelector('iframe[name^="paystack"]');
        if (existingIframe) {
          existingIframe.remove();
        }

        // Load Paystack script
        const existingScript = document.querySelector('script[src="https://js.paystack.co/v1/inline.js"]');
        const scriptPromise = new Promise((resolve, reject) => {
          if (existingScript && window.PaystackPop) {
            resolve();
          } else {
            const script = document.createElement('script');
            script.src = 'https://js.paystack.co/v1/inline.js';
            script.async = true;
            script.onerror = () => reject(new Error('Failed to load Paystack'));
            script.onload = () => resolve();
            document.body.appendChild(script);
          }
        });

        await scriptPromise;
        if (!mounted) return;

        // Setup Paystack
        const newHandler = window.PaystackPop.setup({
          key: import.meta.env.VITE_PAYSTACK_PUBLIC_KEY,
          email,
          amount: convertedAmount * 100,
          ref: reference,
          currency: 'NGN',
          channels: ['card'],
          label: 'TextureMap Generator',
          subtext: `$${amount}`,
          metadata: {
            custom_fields: [
              {
                display_name: "Price",
                value: `$${amount}`
              }
            ]
          },
          customizations: {
            title: "TextureMap Generator",
            description: `Price: $${amount}`,
            logo: window.location.origin + "/TMG/header.png"
          },
          callback: (response) => {
            if (!mounted) return;
            onSuccess(response);
            const tokenData = `${response.reference}-${Date.now()}`;
            const downloadToken = btoa(tokenData);
            navigate('/download', { 
              state: { 
                token: downloadToken,
                reference: response.reference
              },
              replace: true 
            });
          },
          onClose: () => {
            if (!mounted) return;
            onClose();
          }
        });

        if (!mounted) return;
        setHandler(newHandler);
        
        // Inject custom CSS to hide NGN amount
        const style = document.querySelector('style[data-paystack-style]') || document.createElement('style');
        style.textContent = `
          .paystack-amount-display,
          .paystack-currency-display,
          .paystack-amount-section {
            display: none !important;
          }
          .paystack-popup-content {
            z-index: 99999 !important;
          }
          .paystack-overlay {
            z-index: 99998 !important;
          }
        `;
        style.setAttribute('data-paystack-style', '');
        if (!style.parentNode) {
          document.head.appendChild(style);
        }

        // Open the payment modal
        requestAnimationFrame(() => {
          if (mounted) {
            newHandler.openIframe();
            setIsLoading(false);
          }
        });

      } catch (error) {
        console.error('Payment initialization error:', error);
        if (mounted) {
          setIsLoading(false);
          // Only show error if it's a real error, not just modal closing
          if (error.message !== 'Payment closed' && error.message !== 'Failed to load Paystack') {
            alert('Unable to process payment. Please try again.');
            onClose();
          }
        }
      }
    };

    initializePayment();

    return () => {
      mounted = false;
      if (handler) {
        try {
          handler.close();
        } catch (e) {
          console.error('Error closing payment handler:', e);
        }
      }
      // Clean up any leftover iframes
      const iframe = document.querySelector('iframe[name^="paystack"]');
      if (iframe) {
        iframe.remove();
      }
    };
  }, [email, amount, reference, onSuccess, onClose, navigate]);

  if (isLoading) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-[#1a1a1a]/80 backdrop-blur-md p-6 rounded-xl text-white">
          Initializing payment...
        </div>
      </div>
    );
  }

  return null;
};

export default PaystackPayment;
